import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import logo from "../../../../src/assets/blades-in-the-dark/logo-blades-in-the-dark.png";
import sectionDivider from "../../../../src/assets/blades-in-the-dark/session01/blades-in-the-dark-page-banner-session-01.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";
import "./blades-in-the-dark-styles.scss";
import scoundrels from "../../../../src/assets/blades-in-the-dark/session01/akoros-bay-company-scoundrels.webp";
import bodo from "../../../../src/assets/blades-in-the-dark/session01/bodo-fancy-bodasart-token.webp";
import dane from "../../../../src/assets/blades-in-the-dark/session01/dane-hollow.webp";
import vaurin from "../../../../src/assets/blades-in-the-dark/session01/vaurin-slane-token.webp";
import ghost from "../../../../src/assets/blades-in-the-dark/session01/ghost-beneath-the-pier.webp";
import eels from "../../../../src/assets/blades-in-the-dark/session01/puffer-eels.webp";
import bottle from "../../../../src/assets/blades-in-the-dark/session01/shattered-whiskey.webp";
import factions from "../../../../src/assets/blades-in-the-dark/session01/faction-game-session-01.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "1",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Carter One",
  backgroundColor: "rgba(0, 0, 0, 1) ",
  color: "white",
  marginBottom: "15px",
};

const BITDSession01 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  const section1Data = [
    {
      title:
        "The Akoros Bay Company is incorporated; a new Hawker crew in Crow's Foot",
      anchor: "prisoners",
      sectionTitleCssName: "sectionTitleBITD",
      text: (
        <div className="bitdBodyText">
          <ImageComponent
            image={scoundrels}
            caption="The ABCs"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Alistair 'Paddy' the Cutter. Bodo 'Fancy' Bodosart the Spider. Vaurin 'Nail' Slane the Whisper. Dane 'Lucky Jack' Hollow the Hound. And Fergis, the actual hound."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>

          <p>
            It's another drizzly day in Limmerfield, Crow's Foot. Paddy and
            Griggs are on their dilapidated boat with their cohort quartet of
            thugs known as The Clovers standing guard whilst huddled around a
            barrel fire to keep warm. Vaurin Slane, young bedraggled whisper,
            arrives. Like many in Crow's Foot he heard about the dustup at The
            Lucky Star. He figures Paddy angered The Crow's and may be in need
            of assistance or open to starting a new crew.
          </p>
          <p>
            <ImageComponent
              image={vaurin}
              caption="Varuin"
              cssClass="imageRight"
              handleClick={handleClick}
              modalBody="Vaurin 'Nail' Slane. He can hear the secrets of the dead and he can call lightning storms down on your head. When he whispers, lean in to listen, but not too close."
            ></ImageComponent>
            <ModalComponent
              show={showModal}
              onHide={handleCloseModal}
              modalData={modalData}
            ></ModalComponent>
            As Vaurin gets to know Paddy and Griggs the Fox, two more scoundrels
            arrive looking to start a new crew. Dane "Lucky Jack" Hollow is a
            hound. He is an ex-Imperial military veteran who now takes solace
            betting on fights down at the docks. He arrives with his huge hound
            Fergus to check in on Paddy.
            <br />
            Dane:{" "}
            <span style={{ color: "yellow" }}>
              I heard one of my favorite fighters was in a bit of a ballyhoo
              with The Crows at The Lucky Star. Save that fighting spirit f or
              the ring boy! How are you going to make me money wasting those
              fists in a wayward casino. But really? How are you Paddy?"
            </span>
          </p>
          <p>
            <ImageComponent
              image={bodo}
              caption="Bodo"
              cssClass="imageRight"
              handleClick={handleClick}
              modalBody="Bodo 'Fancy' Bodosart. The spider addicted to luxury. A fast-talking, silf scarf-wearing, slick son of a gun. Keep your hand on your coin and your eyes peeled."
            ></ImageComponent>
            <ModalComponent
              show={showModal}
              onHide={handleCloseModal}
              modalData={modalData}
            ></ModalComponent>
            <ImageComponent
              image={dane}
              caption="Dane"
              cssClass="imageLeft"
              handleClick={handleClick}
              modalBody="Dane 'Lucky Jack' and his hound fergus have all of Doskvol in their sights. Lucky Jack likes lay a little coin on the bare-knuckle fights down by the docks."
            ></ImageComponent>
            <ModalComponent
              show={showModal}
              onHide={handleCloseModal}
              modalData={modalData}
            ></ModalComponent>
            The other arrival is the barber of Crow's Foot, the man named Bodo
            Bodosart but known as "Fancy" thanks to his imppecable attire and
            ever-present silk scarp. Bodo figures war is bad for business. His
            barbershop is a hot spot for gossip, but not so much for generating
            coin. Perhaps Paddy and these other scoundrels can avert war with
            The Crows and figure out an alternate source of income? After all,
            fine silk doesn't purchase itself.
          </p>
          <br />
          <br />
          <hr className="hrBITD" />
          <p>
            The scoundrels sit around brainstorming and decide their best bet is
            to be a HAWKER crew that deals in fine contraband and designer
            drugs. They want to present a "respectable" public face and name
            their crew{" "}
            <span style={{ color: "orange", fontSize: "1.5em" }}>
              Akoros Bay Company
            </span>
            .
            <ImageComponent
              image={factions}
              caption="Faction Game"
              cssClass="imageLeft"
              handleClick={handleClick}
              modalBody="ABC pissed off the Grinders stealing a yacht for lair. With some coin the rivalry was bumped down to -1.<br/> The Sailors helped fix up the boat so that it has Quarters upgrade and with some extra coin they are on friendly terms with ABC.<br/> The Crows are pissed because of the fighting at The Lucky Star. <br/>And Ulf Ironborn is on the board thanks to a hello from cohort Clovers but some distrust of the Akorosi veteran Lucky Jack."
            ></ImageComponent>
            <ModalComponent
              show={showModal}
              onHide={handleCloseModal}
              modalData={modalData}
            ></ModalComponent>
            They are the ABCs and ABC might even be code for quality of
            merchandise for their buyers. The ABC steals a yacht as a lair which
            pisses off The Grinders. The initial position with The Grinders is
            brought down to a -1 with some coin. The Sailors help fix up the
            boat so that it has a Quarters upgrade and with some extra coin they
            are on friendly terms with the ABC. The Crows are at a -1 as they
            seek answers about the Lucky Star incident. And Ulf Ironborn is on
            the board thanks to a visit from the ABC cohort known as the
            Clovers. The how do you do is appreciated, but the Clovers let slip
            Dane was an Imperial soldier in the Unity War and Ulf does not like
            that. His perception of the ABC is neutral at the moment.
          </p>
          <p>
            Bannat of the Deathland Scavengers and co-chaos agent from the Lucky
            Star incident shows up to sip tea and give the heads up that the
            second-in-command of The Crows, a middle-aged tough named Bell is
            hoofing it around Crow's Foot looking for answers regarding the
            fracas at The Lucky Star. Lyssa, the leader of The Crows, is not
            happy about the Lucky Star incident but is distracted solidifying
            her new leandership and trusts Bell to get to the bottom of things.
          </p>
          <p>
            Bannatt also give the ABC notice that the Deadland Scavangers could
            really use some puffer eel. Unlike your standard Doskvol eel, the
            puffer eel is particularly toxic, carrying a hint of deadly poison
            which lends a certain culinary risk and thrill to any dish they
            adorn.
          </p>

          <p>
            <a href="#pageTop">Back to Top</a>
          </p>
        </div>
      ),
    },
  ];
  const section2Data = [
    {
      title:
        "The ABCs stir up some trouble at The Docks in search of puffer eels",
      anchor: "prisoners",
      sectionTitleCssName: "sectionTitleBITD",
      text: (
        <div className="bitdBodyText">
          <p>
            <ImageComponent
              image={eels}
              caption="Puffer Eels"
              cssClass="imageLeft"
              handleClick={handleClick}
              modalBody="Puffer eels. That savory slick eel taste with a hint of poison for the undiscerning palate that enjoys a little danger."
            ></ImageComponent>
            <ModalComponent
              show={showModal}
              onHide={handleCloseModal}
              modalData={modalData}
            ></ModalComponent>
            ABC sails down to the Fishmarken area of The Docks and scours the
            area talking to Sailors about how to find a shipment of puffer eel.
          </p>
          <p>
            ENGAGEMENT roll is successful due to the +2 faction relationship
            with the Sailors. The quartet of newly minted hawkers is directed to
            speak with Barney in boat slip 12 of Fishgut Quay pier.
            <br />
            Bodo speaks with Barney hinting at their desire for puffer eel. The
            CONSORT roll is a partial success. So, Barney won't cut a direct
            deal, but he gives the crew information on official channels. Barney
            says he can't sell his puffer eels outright. The Inspectors keep an
            eye on illicit or semi-controlled goods. Their best bet is to speak
            with Inspector Lyanti in his office in Fishmarket.
            <p>
              BARNEY: LYANTI:{" "}
              <span style={{ color: "yellow" }}>
                "Lyanti is a decent sort. 'E is a bit uvah stickler, but he
                shoots straight for an Inspector type. Lot easier to deal with
                than yer average bluecoat fer shore. Speak wit him; he kin sign
                off on my shipment and get you set straight with a reg'lar
                supply I reckon."
              </span>
            </p>
            <p>
              Vaurin Slane wanders off alone, combing the shadows under the
              skeletal shadows of the pier supports cast over the slimy sand and
              rocks. He wants an edge before they speak with Inspector Lyanti.
              Vaurin wants to find a spirit to interrogate.
              <ImageComponent
                image={ghost}
                caption="Iruvian Ghost"
                cssClass="imageLeft"
                handleClick={handleClick}
                modalBody="Under the docks an Iruvian ghost sits remembering her days as a coachwoman."
              ></ImageComponent>
              <ModalComponent
                show={showModal}
                onHide={handleCloseModal}
                modalData={modalData}
              ></ModalComponent>
            </p>
            <p>
              Vaurin finds the female Iruvian ghost of a former coach driver
              sitting on a rock. She seems to be calm; thinking. Bodo is near
              and encourages Vaurin with a hearty pep talk, a slap on the back,
              and then getting his distance from the whisper lest the spirit go
              berserk and seek to possess someone. This counts as help so Bodo
              takes two stress, Vaurin gets an extra dice, and Vaurin approaches
              the spirit. With a successful ATTUNE roll, Vaurin learns that
              Inspector Lyanti is also Iruvian and his full name is Lyanti Kiva.
              The ghostly images fill Vaurin's mind and he learns that Lyanti is
              obsessed with rooting out leviathan blood smuggling which is why
              he took the position of monitoring shipments at The Docks. He has
              trouble sleeping at night and feels he is not fully respected in
              Akoros society due to his Iruvian background.
            </p>
            <p>
              This information from the spirit gives the crew an edge as they
              approach Inspector Lyanti's office.
              <br />
              LYANTI: <span style={{ color: "yellow" }}>What you want?</span> he
              barks as he hunches over paperwork.{" "}
              <span style={{ color: "yellow" }}>Tell me.</span>
              <br />
              Bodo explains the ABC would like to get a shipment of puffer eel
              from Barney today and a steady supply in the future. He sets a
              fine bottle of whiskey on the desk as an unspoken "gift" and
              Vaurin helps by keeping an eye out.
              <br />
              Bodo's SWAY roll fails. Lyanti balks at the bribe and tells the
              scoundrels to leave his office.
            </p>
            <p>
              VAURIN:{" "}
              <span style={{ color: "yellow" }}>
                We help you if you help us. We're honest.
              </span>{" "}
              <ImageComponent
                image={bottle}
                caption="Shattered Whiskey"
                cssClass="imageLeft"
                handleClick={handleClick}
                modalBody="Vaurin calls down lightning to shatter a bottle of whiskey when it fails as a bribe for Inspector Lyanti."
              ></ImageComponent>
              <ModalComponent
                show={showModal}
                onHide={handleCloseModal}
                modalData={modalData}
              ></ModalComponent>
              Then Vaurin uses his TEMPEST special ability to call a small
              lightning bolt to shatter the whiskey bottle bribe and growls,
              <span style={{ color: "yellow" }}>
                You're gonna have to play ball
              </span>{" "}
              as Paddy smashes his gnarly knuckles on the desk to assist in the
              intimidation.
              <br />
              It's a DESPERATE roll since falling back from the bribe, but with
              a +1 for the bottle lightning show and a +1 from Paddy's assist,
              it is a partial success.
              <br />
              Inspector Lyanti caves and signs the order for the Akoros Bay
              Company to receive shipment of puffer eels today and one per week
              going forward. However, one can see that the fastidious inspector
              is ashamed of caving in the face of intimation and once his wits
              are back and the lightning and fists are a memory, he may well
              carry a grude against the ABC. We shall see.
            </p>
          </p>

          <p>
            NEXT TIME: downtime activities, crew XP, and the next big score.
            <br />
            <br />
            <em>
              What has Bell of The Crow's learned about The Lucky Star incident?
              Does Inspector Lyanti bear a grudge? What do the Deathland
              Scavengers think of the puffer eel?
            </em>
          </p>

          <p>
            <a href="#pageTop">Back to Top</a>
          </p>
        </div>
      ),
    },
  ];

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/blades-in-the-dark/background-doskvol_street-scene-01.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.backgroundWidth = "100vw";
    document.body.backgroundHeight = "100vh";
    document.body.style.backgroundSize = "cover";
  }, []);

  return (
    <Container>
      {/* <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin" 
                    style={{position: 'float', float: 'right',width: '120px', height: '240px'}} marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=smokeraven-20&language=en_US&marketplace=amazon&region=US&placement=9189143701&asins=9189143701&linkId=d1384e0e6b9ef083fee35d7272a71f88&show_border=true&link_opens_in_new_window=true"></iframe> */}
      <Row>
        <Col>
          <div
            style={{
              backgroundColor: "transparent",
              fontFamily: "IM Fell Great Primer",
            }}
          >
            <div style={pageHeader}>
              <main role="main" className="inner cover text-center">
                <a href="#freedom-day" name="pageTop">
                  <img
                    src={logo}
                    style={{ maxWidth: "200px" }}
                    alt="Forbidden Lands Campaign"
                  />
                </a>
              </main>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title className="cardTitle-blades-in-the-dark  ">
                Akoros Bay Company: a quartet of hawker scoundrels ready to get
                high on delivering the goods (aka "the ABCs"). They steal a
                yacht for their lair and get their feet wet intimidating
                Inspector Lyanti at the docks, gaining access to a shipment of
                puffer eels.
                {/* <br />
                <small>
                  <br />☠ Session 01:{" "}
                  <a href="./forbidden-lands-session-01">
                    Freedom Day in Gomsten
                  </a>{" "}
                  ⇒
                </small> */}
              </Card.Title>
              <div>
                <ContentContainer data={section1Data} />
                <ContentContainer data={section2Data} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default BITDSession01;
